<template>
  <main
    class="color-primary h-100 blank-aside-js w-100"
    :class="isOpenAside == 'true' ? 'blank-aside' : ''"
  >
    <section class="trade-detail-container pb-15">
      <div class="d-flex justify-content-center detail-header">
        <img src="/assets/img/team-1.png" alt="" class="company-logo">
        <div class="p-relative">
          <div class="text-center weight-700 fs28 mb-7">
            <div class="d-flex align-items-center icon-group">
              <img src="/assets/img/icons/star-light-blue.svg" alt="" class="icon-img">
              <img src="/assets/img/icons/share-alt-light-blue .svg" alt="" class="mx-10 icon-img">
              <img src="/assets/img/icons/heart-light-blue.svg" alt="" class="icon-img">
            </div>
            PPAI Expo Online
          </div>
          <p class="weight-700 text-center">
            11. - 14. January 2021 | Exhibition for advertising and promotional items 
          </p>
        </div>
      </div>
      <div class="d-flex mt-15 flex-wrap">
        <div class="w-25 p-2">
          <div class="content-block-item df-c mb-5">
             Starts in <b> 11 Days </b>
          </div>
          <div class="content-block-item mb-5">
            <b>{{ $t("Date") }}</b>
            <p>11.01.2021 - 14.01.2021*</p>
            <p>Monday - Thursday, 4 days</p>
          </div>
          <div class="content-block-item mb-5">
            <b>{{ $t("Trade Show Contact") }}</b>
            <p>webmaster@ppai.org</p>
            <p>expo.ppai.org</p>
          </div>
          <div class="content-block-item mb-5">
            <b>{{ $t("Audience") }}</b>
            <p>professional visitors only</p>
          </div>
          <div class="content-block-item mb-5">
            <b>{{ $t("Cycle") }}</b>
            <p>annuaaly</p>
          </div>
          <div class="content-block-item mb-5">
            <b>{{ $t("Local time") }}</b>
            <p>12:05 h (UTC +01:00)</p>
          </div>
        </div>
        <div class="w-75">
          <div class="p-2">
            <div class="content-block-item">
              <p>PPAI Expo is an exhibition for promotional and promotion in Las Vegas. Here a number of international exhibitors, the visitors their latest products for successful marketing. In addition, lectures and workshops on new marketing channels offered. It is the most important industry trade show at the start of the year.</p>
              <p>
                <b>Please note: Due to the effects of the corona virus, the fair will be held as an online event only.</b>
              </p>
            </div>
          </div>
          <div class="d-flex flex-wrap">
            <div class="w-50 p-2">
              <div class="content-block-item">
                <p class="mb-5">
                  <b>{{ $t("Fair location") }}</b>
                </p>
                <p>
                  CICC Cairo International Convention Center, El Nasr Rd., 11757 Cairo, Cairo, Egypt
                </p>
                <div class="d-flex mt-10 justify-content-between">
                  <p class="weight-700 color-blue8">
                    {{ $t("Open in Map") }}
                  </p>
                  <img src="/assets/img/fair-location-map.png" alt="">
                </div>
              </div>
            </div>
            <div class="w-50 p-2">
              <div class="content-block-item">
                <p class="mb-5">
                  <b>{{ $t("Fair organizer") }}</b>
                </p>
                <p>
                  PPAI Promotional Products Ass. International, 3125 Skyway Circle North, 75038-3526 Living, USA
                </p>
                <p>
                  Fax: +1 (9)72 2583007
                </p>
                <p class="color-blue9">priscillab@ppa.org</p>
                <p class="color-blue9">www.ppa.org</p>
              </div>
            </div>
          </div>
          <div class="p-2">
            <div class="content-block-item">
              <b>{{ $t("Fairs of these Sectors") }}</b>
              <div class="d-flex flex flex-wrap mt-5">
                <router-link :to="{ name: 'TradeShowsCategory' }" class="primary-btn small-btn m-24">
                  {{ $t("Advertising Trade Shows") }}
                </router-link>
                <button class="primary-btn small-btn m-24" @click="openTradeDetailModal()">
                  {{ $t("Marketing Trade Shows") }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="w-25 p-2 d-flex align-items-center">
          <img src="/assets/img/icons/print.svg" alt="" class="action-icon">
          <img src="/assets/img/icons/mail.svg" alt="" class="action-icon mx-5">
          <div class="color-blue7 df-c" @click="openTradeDetailBookModal()">
            <img src="/assets/img/icons/date-blue.svg" alt="" class="mx-2">
            {{ $t("More dates") }}
          </div>
        </div>
        <div class="w-75 px-10">
          <b>* {{ $t("Disclaimer") }}:</b>
          No responsibility is taken for the correctness of this information. Errors and alterations excepted! Fair dates and exhibition sites are subject to change by the respective trade fair organiser.
        </div>
      </div>
      <div class="mx-5 content-block-item d-flex flex-wrap mt-10">
        <div class="w-30">
          <p class="mb-2 text-center">
            <b>Vacation packages </b> to <b>Cairo, Egypt</b> 
          </p>
          <img src="/assets/img/berlin2.png" alt="" class="w-100">
        </div> 
        <div class="w-70 p-relative">
          <h4 class="text-center mb-5">
            {{ $t("Recommended_VACATION_PACKAGE") }}
          </h4>
          <div class="footer-share d-flex align-items-center p-absolute">
            <img src="/assets/img/icons/star-light-black.svg" alt="" class="mx-5">
            <img src="/assets/img/icons/share-alt-light-black.svg" alt="">
          </div>
          <div class="d-flex flex-wrap">
            <div class="text-center flight-part fs16 w-50 d-flex flex-column align-items-center">
              <p class="mb-5 mt-15">
                Flights to <b>London, England</b> 
              </p>
              <FlightDesData class="px-15"></FlightDesData>
              <button class="primary-btn small-btn d-flex details-btn mt-10" @click="openTripSummaryModal()">
                <img src="/assets/img/icons/info-white.svg" alt="" class="mx-2">
                {{ $t("Flight details") }}
              </button>
            </div>
            <div class="hotel-part text-center w-25 d-flex flex-column align-items-center">
              <h5 class="mt-20">
                Hotel name
              </h5>
              <h5>
                Bed & BreakFast
              </h5>
              <RateStar :starNumber="5" class="mt-5"></RateStar>
              <button class="primary-btn small-btn d-flex details-btn mt-20" @click="openTripSummaryModal()">
                <img src="/assets/img/icons/info-white.svg" alt="" class="mx-2">
                {{ $t("Hotel details") }}
              </button>
            </div>
            <div class="w-25 d-flex flex-column align-items-center justify-content-end">
              <p class="mb-5">
                {{ $t("Price") }}: <span><b> 2596 ₪</b></span> 
              </p>
              <button class="primary-btn small-btn booking-btn">
                {{ $t("Book") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <router-link :to="{ name: 'SelectedResult' }" class="primary-btn mt-10 w-100 choose-dif_btn">
        {{ $t("Choose a different recommended vacation package") }}
      </router-link>
    </section>
    <TradeDetailModal></TradeDetailModal>
    <TradeDetailBookModal></TradeDetailBookModal>
    <TripSummaryModal></TripSummaryModal>
  </main>
</template>

<script>
import  RateStar from "../../../components/RateStar";
import  TradeDetailModal from "../../../components/tourism/tradeShow/TradeDetailModal.vue";
import  TradeDetailBookModal from "../../../components/tourism/tradeShow/TradeDetailBookModal.vue";
import  TripSummaryModal from "../../../components/tourism/tradeShow/TripSummaryModal.vue";
import  FlightDesData from "../../../components/tourism/FlightDesData.vue";

export default {
  name: 'TradeShowsDetails',
  components: {
    RateStar,
    TradeDetailModal,
    TradeDetailBookModal,
    TripSummaryModal,
    FlightDesData
  },
  methods: {
    openTradeDetailModal() {
      this.$modal.show("TradeDetailModal");
    },
    openTradeDetailBookModal() {
      this.$modal.show("TradeDetailBookModal");
    },
    openTripSummaryModal() {
      this.$modal.show("TripSummaryModal");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/tourism.scss";
.trade-detail-container {
  max-width: 1250px;
  padding: 0 20px;
  margin: 0 auto;
  .detail-header {
    position: relative;
    padding: 10px 110px;
    @media (max-width: 400px) {
      padding: 10px;
    }
    .company-logo {
      width: 90px;
      height: 90px;
      position: absolute;
      left: 5px;
      @media (max-width: 400px) {
        position: relative;
        left: -10px;
      }
    }
    .icon-group {
      position: absolute;
      @media (max-width: 800px) {
        position: relative;
      }
      left: 0;
      .icon-img {
        height: 30px;
      }
    }
  }
  .content-block-item {
    border-radius: 10px;
    background-color: #fff;
    padding: 10px 20px;
  }

  .action-icon {
    width: 25px;
  }

  .flight-part {
    border-right: 1px solid #dfdfdf;
  }

  .details-btn {
    height: 32px;
    font-size: 16px;
    color: #002036;
    background-color: #D6EDFF;
  }
  .hotel-part {
    border-right: 1px solid #dfdfdf;
  }
  .booking-btn {
    background-color: #0F9D58;
    width: 70%;
  }

  .footer-share {
    top: 20px;
    right: 0px;
  }
  .choose-dif_btn {
    max-width: unset;
    background-color: #002036;
  }
  @media (max-width: 600px) {
    .choose-dif_btn {
      padding: 5px;
      text-align: center;
      height: auto;
    }
  }
}

@media (max-width: 900px) {
  .w-25, .w-75, .w-30, .w-70 {
    width: 100% !important;
  }
  .flight-part {
    border-right: none !important;
    &.w-50 {
      width: 100% !important;
    }
  }
  .hotel-part {
    border-right: none !important;
  }
}
@media (max-width: 600px) {
  .w-50 {
    width: 100% !important;
  }
}

.rtl-type {
  .trade-detail-container {
    .footer-share {
      right: unset;
      left: 0;
    }
  }
}

</style>